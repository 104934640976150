<template>
  <div>
    <div class="register-form-wrap">
      <div class="container content bg-white mar-tb10">
        <div class="flex" style="padding: 0 40px;">
          <div style="width: 200px;border-right: 2px solid #C0C4CC;">
            <el-image
              style="width: 150px;height: 150px;margin-bottom: 20px;"
              fit="cover"
              :src="$store.state.setting.registerpageimage"
            ></el-image>
            <div class="pad-b20 fz-16">忘记密码</div>
            <el-steps class="register-step" direction="vertical" :active="step">
              <el-step title="验证手机号"></el-step>
              <el-step title="重置密码"></el-step>
              <el-step title="重置成功"></el-step>
            </el-steps>
          </div>
          <div class="register-from-wrap">
            <template v-if="step === 1">
              <div style="color: #606266;padding: 6px 24px 50px;font-size: 16px;">忘记密码-验证手机号</div>
              <el-form autocomplete="off" :model="smsForm" ref="smsForm" :rules="smsFormRules" label-width="100px">
                <el-form-item label="手机号" prop="phone">
                  <el-input placeholder="输入手机号" v-model="smsForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                  <el-input placeholder="请输入验证码" v-model="smsForm.code">
                    <el-button @click="captchaConfig.codeTimer.onSendCodeClick('smsForm')"
                               :disabled="captchaConfig.codeTimer.disabled"
                               slot="append">
                      {{ captchaConfig.codeTimer.text }}
                    </el-button>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitPhoneInfo('smsForm')">下一步
                  </el-button>
                </el-form-item>
              </el-form>
            </template>
            <template v-else-if="step === 2">
              <div style="color: #606266;padding: 6px 24px 50px;font-size: 16px;">忘记密码-重置密码</div>
              <el-form ref="passwordForm" :model="passwordForm" label-width="100px"
                       :rules="formRules">
                <el-form-item label="账号密码" prop="password">
                  <el-input placeholder="6—20位字母、数字、特殊字符组合" type="password"
                            v-model="passwordForm.password"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="rePassword">
                  <el-input placeholder="6—20位字母、数字、特殊字符组合" type="password"
                            v-model="passwordForm.rePassword"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button @click="--step">上一步</el-button>
                  <el-button type="primary" @click="submitValidateForm('passwordForm')">确认修改
                  </el-button>
                </el-form-item>
              </el-form>
            </template>
            <template v-else-if="step === 3">
              <div style="padding: 70px 0 30px;" class="tc register-success-container">
                <i class="el-icon-success"></i>
                <div class="color-main">
                  <div class="mar-b15">
                    <span class="mar-r5">重置成功</span>
                    <span>
                      <count-down
                        @finish="$router.push('/')" format="ss秒后自动跳转首页"
                        :time="5 * 1000"></count-down>
                    </span>
                  </div>
                  <router-link to="/">
                    <el-button type="primary">去首页</el-button>
                  </router-link>
                </div>
              </div>
            </template>
          </div>
        </div>
        <el-dialog
          title="注册协议"
          :visible.sync="protocolConfiguration.showRegister"
          class="register-dialog"
          center>
          <div class="rich-text" v-html="protocolConfiguration.register"></div>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="protocolConfiguration.showRegister = false">确 定
            </el-button>
          </div>
        </el-dialog>
        <el-dialog class="captcha-dialog" center :visible.sync="captchaConfig.captcha.show">
          <div class="captcha" ref="captcha"></div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
  import asyncValidator from "@/resources/js/async-validator";
  import {sendPhoneCode} from "@/apis/isrsms";
  import {forgetPassword} from "@/apis";

  require('@/resources/libs/jigsaw/jigsaw.min')
  export default {
    name: 'Forgetpassword',
    components: {
      CountDown: () => import('@CMP/common/CountDown')
    },
    data() {
      return {
        protocolConfiguration: {
          showRegister: false,
          register: "222",
        }, smsForm: {
          phone: '',
          code: '',
          type: 0,
          autoRegister: true,
        }, smsFormRules: {
          phone: [
            {required: true, message: '手机号不能为空', trigger: 'blur'},
            {validator: asyncValidator.validPhone, message: '手机号格式不正确', trigger: 'blur'}
          ], code: [
            {required: true, message: '验证码不能为空', trigger: 'blur'},
            {min: 6, max: 6, message: '密码长度为6位', trigger: 'blur'},
          ]

        }, passwordForm: {
          password: "",
          rePassword: "",
        }, formRules: {
          password: [
            {required: true, message: '请输入密码', trigger: ['change', 'blur']},
            {min: 6, max: 20, message: '密码长度在6~20位之间', trigger: 'blur'},
          ],
          rePassword: [
            {required: true, message: '再次输入密码', trigger: ['change', 'blur']},
            {
              message: '两次输入密码不一致!', trigger: ['change', 'blur'],
              validator: (rule, value, callback) => {
                let password = this.passwordForm.password;
                value === password ? callback()
                  : callback(new Error(rule.message))
              }
            },
          ],
        },
        registerImageUrl: '',
        step: 1,
        captchaConfig: {
          codeTimer: {
            disabled: false,
            time: 60,
            currentTime: 60,
            text: '获取验证码',
            onSendCodeClick: (formName) => {
              this.captchaConfig.codeTimer.sendCode(formName);
            },
            sendCode: (formName) => {
              this.$refs[formName].validateField('phone', valid => {
                if (valid == '') {
                  this.captchaConfig.captcha.initCaptcha()
                    .then(() => {
                      this.captchaConfig.codeTimer.doSendCode();
                    })
                }
              });
            },
            start: () => {
              let {disabled, currentTime} = this.captchaConfig.codeTimer;
              if (disabled) {
                return;
              }
              this.captchaConfig.codeTimer.disabled = true;
              this.captchaConfig.codeTimer.timer = setInterval(() => {
                if (currentTime <= 1) {
                  this.captchaConfig.codeTimer.stop();
                } else {
                  --currentTime;
                  this.captchaConfig.codeTimer.text = `${currentTime}秒后重试`;
                }
              }, 1000);
            },
            stop: () => {
              if (this.captchaConfig.codeTimer.timer) {
                clearInterval(this.captchaConfig.codeTimer.timer);
              }
              this.captchaConfig.codeTimer.currentTime = this.captchaConfig.codeTimer.time;
              this.captchaConfig.codeTimer.text = '重新获取验证码';
              this.captchaConfig.codeTimer.disabled = false;
            },
            doSendCode: () => {
              let {disabled} = this.captchaConfig.codeTimer;
              let phone = this.smsForm.phone;
              if (disabled) {
                return;
              }
              this.captchaConfig.codeTimer.start();
              sendPhoneCode({
                phone: phone
              }).then(res => {
                this.captchaConfig.codeTimer.start();
                this.$message.success('发送验证码成功，请注意查收！');
              }).catch(err => {
                this.captchaConfig.codeTimer.stop();
              });
            },
          },
          captcha: {
            show: false,
            initCaptcha: () => {
              return new Promise(resolve => {
                if (!this.captchaConfig.captcha.show) {
                  this.captchaConfig.captcha.show = true;
                }
                this.$nextTick(() => {
                  this.$refs.captcha.innerHTML = '';
                  window.jigsaw.init({
                    el: this.$refs.captcha,
                    onSuccess: () => {
                      this.$refs.captcha.innerHTML = '';
                      this.captchaConfig.captcha.show = false;
                      resolve();
                    },
                    onFail: () => {
                    },
                    onRefresh: () => {
                    }
                  })
                })
              })
            },
          }
        }
      }
    },
    mounted() {
    }
    ,
    watch: {
      step: {
        handler() {
          [this.$refs['informationForm'], this.$refs['validateForm']].forEach(item => {
            if (item) {
              item.clearValidate();
            }
          })
        }
      }
      ,
    }
    ,
    methods: {
      submitPhoneInfo(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.nextStep();
          }
        });
      }, submitValidateForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let phone = this.smsForm.phone;
            let code = this.smsForm.code;
            let password = this.passwordForm.password
            forgetPassword({phone, code, password}).then(res => {
              this.nextStep();
            })
          }
        });
      },
      nextStep() {
        if (this.step < 3) {
          this.step++
        }
      }
      ,
    }
  }
</script>

<style scoped lang="scss">
  .custom-radio {
    width: 700px;
    margin-bottom: 50px;

    ::v-deep {
      .el-radio {
        text-align: center;
      }

      .el-form-item__content {
        text-align: center;
        margin-left: 0 !important;

        > div {
          margin-left: -150px;
          margin-top: -10px;
        }
      }
    }
  }

  .content {
    padding: 30px 0;
  }

  .account-type-item {
    font-size: 18px;
    background-image: linear-gradient(to bottom, #f9f9f9, #eeeeee);
    padding: 10px 0;
    cursor: pointer;

    &.active {
      background-image: linear-gradient(to bottom, #7284ce, #4563d5);
      color: white !important;
    }

    i {
      margin-right: 5px;
    }
  }

  .register-step {
    height: 200px;
  }

  .register-from-wrap {
    width: 800px;

    .el-form {
      margin-left: 180px;
      width: 500px;
      margin-top: 10px;
    }

    .el-radio.is-bordered {
      border-radius: unset !important;
    }
  }

  .register-success-container .el-icon-success {
    font-size: 100px;
    color: $main-color;
    margin-bottom: 15px;
  }

  .el-radio.is-checked a {
    color: $main-color !important;
  }

  .register-dialog ::v-deep .el-dialog__body {
    max-height: 50vh;
    overflow-y: auto;
  }
</style>
